import { FC, MouseEventHandler } from "react";

interface Props {
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  checked: boolean;
}

const ToggleButton: FC<Props> = ({ label, onClick, checked }): JSX.Element => {
  return (
    <button
      className={[
        "inline-block rounded-full px-16 py-8 text-sm whitespace-nowrap",
        checked ? "bg-gray-950 text-white" : "text-black bg-white",
        checked ? "" : "hover:bg-black hover:text-white",
        "transition duration-200",
      ].join(" ")}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
export default ToggleButton;
