import OneLineScrollableContainer from "@/components/OneLineScrollableContainer";
import ToggleButton from "@/components/ToggleButton";
import { TopSearchCondition } from "@/hooks/useTopSearchConditions";
import { FC, PropsWithChildren } from "react";
import MoreBtnLink from "@/components/MoreBtnLink";

interface Props {
  conditions: TopSearchCondition[];
  moreLink: string;
  displayMoreLink: boolean;
}

const TopItemListContainer: FC<PropsWithChildren<Props>> = ({
  conditions,
  moreLink,
  displayMoreLink,
  children,
}): JSX.Element => {
  return (
    <div className="py-8 space-y-24">
      <nav className="flex items-center gap-12">
        <div className="w-full">
          <OneLineScrollableContainer scrollContainerClassName="[&>*+*]:ml-4">
            {conditions.map(({ label, selected, onClick }) => (
              <ToggleButton
                key={label}
                checked={selected}
                label={label}
                onClick={onClick}
              />
            ))}
          </OneLineScrollableContainer>
        </div>
      </nav>

      <div>{children}</div>

      {displayMoreLink && <MoreBtnLink href={moreLink} />}
    </div>
  );
};

export default TopItemListContainer;
