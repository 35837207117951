import { useEffect } from "react";
import useAnalytics from "./useAnalytics";

const usePageView = (
  makerId?: number,
  params?: { page_path?: string; page_title?: string },
): void => {
  const { pageView } = useAnalytics(makerId);

  useEffect(() => {
    pageView(params?.page_path, params?.page_title);
  }, [pageView]);
};

export default usePageView;
