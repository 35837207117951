import { ArchitectureSearchQueryParameter } from "@/types/ArchitectureSearchQueryParameter";
import useSearchQueryParameter, {
  SearchQueryParameterResponse,
} from "@/hooks/useSearchQueryParameter";
import { useMemo } from "react";
import usePagination, { PaginationResponse } from "@/hooks/usePagination";
import {
  AvailablePart,
  ColorType,
  ProjectConstructionType,
  ProjectResponse,
  ProjectStructureType,
} from "@/web-client/api";
import client from "@/utils/api/client";

interface UseProjectSearchReturn {
  keyword?: string;
  buildingTypeId?: number;
  pagination: PaginationResponse<ProjectResponse>;
  parameter: SearchQueryParameterResponse<ArchitectureSearchQueryParameter>;
}

const useProjectSearch = (
  path = "/architectures/projects/search",
  defaultQuery = {},
  limit = 20,
): UseProjectSearchReturn => {
  const parameter = useSearchQueryParameter<ArchitectureSearchQueryParameter>(
    path,
    defaultQuery,
  );

  const {
    building_type_id,
    construction_type,
    structure_type_id,
    max_floor_space,
    min_floor_space,
    material_id,
    color_type_ids,
    part_ids,
    keyword,
    use_type_id,
  } = parameter.query;

  const buildingTypeId = useMemo<number | undefined>(() => {
    return building_type_id ? Number(building_type_id) : undefined;
  }, [building_type_id]);

  const structureTypeId = useMemo<number | undefined>(() => {
    return structure_type_id ? Number(structure_type_id) : undefined;
  }, [structure_type_id]);

  const pagination = usePagination<ProjectResponse>(
    parameter.queryController.toPath(),
    limit,
    async ({ limit, page }) => {
      const { data } = await client.projectsSearchGet({
        keyword,
        buildingTypeId,
        structureTypeId,
        constructionType: construction_type
          ? (Number(construction_type) as ProjectConstructionType)
          : undefined,
        minFloorSpace: min_floor_space ? Number(min_floor_space) : undefined,
        maxFloorSpace: max_floor_space ? Number(max_floor_space) : undefined,
        materialId: material_id ? Number(material_id) : undefined,
        colorTypeIds: color_type_ids
          ? color_type_ids.split(",").map((id) => Number(id) as ColorType)
          : undefined,
        partIds: part_ids
          ? part_ids.split(",").map((id) => Number(id) as AvailablePart)
          : undefined,
        useTypeId: use_type_id ? Number(use_type_id) : undefined,
        limit,
        offset: limit * (page - 1),
      });

      return data;
    },
    { waitForAuth: true },
  );

  return {
    pagination,
    parameter,
    buildingTypeId,
    keyword,
  };
};

export default useProjectSearch;
