import { FC, PropsWithChildren } from "react";
import Tab, { TabProps } from "@/components/Tab";
import OneLineScrollableContainer from "@/components/OneLineScrollableContainer";

interface Props {
  tabs: TabProps[];
}

const TabsLayout: FC<PropsWithChildren<Props>> = ({
  tabs,
  children,
}): JSX.Element => {
  return (
    <div className="space-y-12 laptop:space-y-20">
      <div className="border-b border-solid border-primary laptop:block ">
        <OneLineScrollableContainer scrollContainerClassName="[&>*+*]:ml-16">
          {tabs.map((tab, index) => {
            return <Tab key={index} {...tab} />;
          })}
        </OneLineScrollableContainer>
      </div>

      <div className="pb-12 laptop:pb-20">{children}</div>
    </div>
  );
};

export default TabsLayout;
