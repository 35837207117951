import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { FC, useContext } from "react";
import IconArrowRight from "../assets/imgs/svg/icon-arrow-right.svg";
import IconArrowLeft from "../assets/imgs/svg/icon-arrow-left.svg";

const OneLineScrollableContainer = ({
  wrapperClassName = "",
  scrollContainerClassName = "",
  children,
}): JSX.Element => {
  return (
    <ScrollMenu
      wrapperClassName={["relative", wrapperClassName].join(" ")}
      scrollContainerClassName={[
        "hidden-scrollbar",
        scrollContainerClassName,
      ].join(" ")}
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
    >
      {children}
    </ScrollMenu>
  );
};

const LeftArrow: FC = (): JSX.Element => {
  const { isFirstItemVisible, scrollPrev } = useContext(VisibilityContext);

  if (isFirstItemVisible) return null;

  return (
    <button
      className="absolute left-0 inset-y-0 z-10 w-[44px] flex items-center justify-start"
      style={{
        background: `linear-gradient(90deg, rgba(244,245,246,1) 0%, rgba(244,245,246,1) 64%, rgba(244,245,246,0) 100%)`,
      }}
      onClick={() => {
        scrollPrev();
      }}
      name="左に進む"
    >
      <IconArrowLeft width={24} height={24} />
    </button>
  );
};

const RightArrow = (): JSX.Element => {
  const { isLastItemVisible, scrollNext } = useContext(VisibilityContext);

  if (isLastItemVisible) return null;

  return (
    <button
      className="absolute right-0 inset-y-0 bg-yellow-500 z-10 w-[44px] flex items-center justify-end"
      style={{
        background: `linear-gradient(-90deg, rgba(244,245,246,1) 0%, rgba(244,245,246,1) 64%, rgba(244,245,246,0) 100%)`,
      }}
      onClick={() => {
        scrollNext();
      }}
      disabled={isLastItemVisible}
      name="右に進む"
    >
      <IconArrowRight width={24} height={24} />
    </button>
  );
};

export default OneLineScrollableContainer;
