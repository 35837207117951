import client from "@/utils/api/client";
import { FC } from "react";
import useSWR from "swr";
import BoardItem from "@/features/board/components/BoardItem";
import Link from "next/link";

const RecommendBoards: FC = (): JSX.Element => {
  const { data: boards } = useSWR(
    "/recommend_boards",
    async () => {
      const { data } = await client.pickupsGet();

      return data.boards || [];
    },
    {
      revalidateIfStale: false, // キャッシュがあれば再検証しない
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );

  if (!boards || boards.length < 1) return null;

  return (
    <section className="space-y-8">
      <header className="flex justify-between items-center gap-8">
        <h2 className="font-bold text-sm text-primary">
          おすすめのマテリアルボード
        </h2>
        <Link className="text-link text-sm" href={"/boards"}>
          すべてを見る
        </Link>
      </header>
      <div className="small:whitespace-nowrap small:overflow-scroll small:space-x-12 laptop:grid grid-cols-3 gap-12 hidden-scrollbar">
        {boards.map((board) => (
          <div key={board.id} className="small:w-[80%] small:inline-block">
            <BoardItem board={board} />
          </div>
        ))}
      </div>
    </section>
  );
};
export default RecommendBoards;
