import { useMemo } from "react";
import { SearchQueryParameterResponse } from "@/hooks/useSearchQueryParameter";

interface Entity {
  id: number;
  name: string;
}

export interface TopSearchCondition {
  label: string;
  value: number;
  onClick: () => void;
  selected: boolean;
}

interface UseTopSearchConditionsArgs<T> {
  queryKey: "building_type_id" | "use_type_id" | "category_id";
  entities: Entity[];
  parameter: SearchQueryParameterResponse<T>;
}

interface UseTopSearchConditionsReturn {
  conditions: TopSearchCondition[];
}

const useTopSearchConditions = <
  T extends {
    building_type_id?: string;
    use_type_id?: string;
    category_id?: string;
  },
>({
  queryKey,
  entities,
  parameter: { queryController, query },
}: UseTopSearchConditionsArgs<T>): UseTopSearchConditionsReturn => {
  const conditions = useMemo<TopSearchCondition[]>(() => {
    return [{ name: "すべて", id: undefined }, ...entities].map((entity) => {
      const selected =
        query[queryKey] ===
        (entity.id !== undefined ? entity.id.toString() : undefined);

      return {
        label: entity.name,
        value: entity.id,
        selected: selected,
        onClick: () => {
          if (entity.id !== undefined) {
            queryController.addQuery(queryKey, entity.id.toString());
          } else {
            queryController.deleteQuery(queryKey);
          }
          queryController.submitQuery(undefined, false);
        },
      };
    });
  }, [entities, queryKey, query, queryController]);

  return { conditions };
};

export default useTopSearchConditions;
