import DefaultLayout from "@/layouts/DefaultLayout";
import usePageView from "@/hooks/usePageView";
import { NewsEntity, ProductKind } from "@/web-client/api";
import { NextPage } from "next";
import TabsLayout from "@/components/TabsLayout";
import SearchBar from "@/components/SearchBar";
import Link from "next/link";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useDevice from "@/hooks/useDevice";
import { FC, useMemo } from "react";
import TopItemListContainer from "@/components/TopItemListContainer";
import ProjectItemList from "@/features/project/components/ProjectItemList";
import useProjectSearch from "@/features/project/hooks/useProjectSearch";
import ProjectItem from "@/features/project/components/ProjectItem";
import useTopSearchConditions from "@/hooks/useTopSearchConditions";
import { ArchitectureSearchQueryParameter } from "@/types/ArchitectureSearchQueryParameter";
import useBuildingTypes from "@/hooks/useBuildingTypes";
import useAllUseTypes from "@/hooks/useAllUseTypes";
import PostItem from "@/features/post/components/PostItem";
import usePostSearch from "@/features/post/hooks/usePostSearch";
import PostItemList from "@/features/post/components/PostItemList";
import { useRouter } from "next/router";
import { ProductSearchQueryParameter } from "@/types/ProductSearchQueryParameter";
import useProductSearch from "@/features/product_variation/hooks/useProductSearch";
import ProductVariationItemList from "@/features/product_variation/components/ProductVariationItemList";
import ProductVariationItem from "@/features/product_variation/components/ProductVariationItem";
import useExistCategories from "@/hooks/useExistCategories";
import RecommendBoards from "@/features/board/components/RecommendBoards";
import Image from "next/image";
import { theme } from "tailwind.config";

enum IndexSearchKind {
  Project = 1,
  Post,
  BuildingMaterial,
  Interior,
}

type Props = {
  news?: NewsEntity[];
};

const IndexPage: NextPage<Props> = (): JSX.Element => {
  usePageView();

  const router = useRouter();
  const { query } = router;
  const kind = useMemo(() => {
    switch (query.kind) {
      case "project":
        return IndexSearchKind.Project;

      case "building_material":
        return IndexSearchKind.BuildingMaterial;

      case "interior":
        return IndexSearchKind.Interior;

      case "post":
        return IndexSearchKind.Post;

      default:
        return IndexSearchKind.Project;
    }
  }, [query]);

  const { isSp, isPc } = useDevice();

  return (
    <DefaultLayout>
      <main className="space-y-32">
        <header
          className={[
            "relative bg-black bg-cover bg-center text-white laptop:text-center flex flex-col items-start justify-center z-10",
            "h-auto pt-32 pb-64 px-24",
            "laptop:h-[560px] laptop:p-24 laptop:items-center",
          ].join(" ")}
        >
          <Image
            className="object-cover"
            src="/imgs/hero.webp"
            sizes="100vw"
            quality={100}
            priority={true}
            alt={"道の駅しょうなん再整備工事 by NASCA"}
            fill
          />
          <div className="absolute inset-0 bg-black bg-opacity-25" />

          <div className="relative flex flex-col gap-32">
            <div className="space-y-12">
              <h1 className="text-[6.4vw] laptop:text-4xl font-bold tracking-wider">
                TECTUREは、
                <br className="laptop:hidden" />
                すべての設計者のための
                <br className="laptop:hidden" />
                データベースです。
              </h1>
              <p className="leading-loose tracking-wider text-sm laptop:text-lg">
                設計者の複雑な業務フローをサポートし、
                <br />
                クリエイティブな環境を生み出すことを目指しています。
              </p>
            </div>

            <div className="max-w-[640px] laptop:mx-auto w-full">
              <SearchBar
                placeholder="建物や家具の名前で検索してみよう。"
                backgroundColor={theme.colors.gray[50]}
                className="laptop:min-w-[640px] w-full"
              />
            </div>
          </div>

          <div
            className={[
              "text-white text-xs absolute flex items-center opacity-80 space-x-12",
              "laptop:absolute laptop:bottom-[16px] laptop:right-[16px]",
              "small:absolute small:w-full small:inset-x-0 small:bottom-0 small:py-16 small:px-24 small:whitespace-nowrap small:overflow-scroll",
            ].join(" ")}
          >
            <div className="space-x-4">
              <Link href={"/projects/3182"} className="hover:underline">
                道の駅しょうなん再整備工事
              </Link>
              <span>by</span>
              <Link
                href={"/teams/18/projects"}
                className="font-bold hover:underline"
              >
                NASCA
              </Link>
            </div>

            <div className="space-x-4">
              <span>photo by</span>
              <span className="font-bold">淺川敏</span>
            </div>
          </div>
        </header>

        <div className="px-16 laptop:px-24 space-y-20">
          <Link href="/releases/tss">
            {isPc && (
              <div className="relative aspect-[2774/380]">
                <Image
                  src="/imgs/banner.webp"
                  alt="サンプル請求サービスはじめます"
                  priority={true}
                  fill
                />
              </div>
            )}
            {isSp && (
              <div className="relative aspect-[1094/782]">
                <Image
                  src="/imgs/banner-sp.webp"
                  alt="サンプル請求サービスはじめます"
                  priority={true}
                  fill
                />
              </div>
            )}
          </Link>

          <RecommendBoards />

          <div className="min-h-screen">
            <TabsLayout
              tabs={[
                {
                  isCurrent: kind === IndexSearchKind.Project,
                  to: `/?kind=project`,
                  label: "プロジェクト",
                  replace: false,
                },
                {
                  isCurrent: kind === IndexSearchKind.Post,
                  to: `/?kind=post`,
                  label: "写真",
                  replace: false,
                },
                {
                  isCurrent: kind === IndexSearchKind.BuildingMaterial,
                  to: `/?kind=building_material`,
                  label: "建材",
                  replace: false,
                },
                {
                  isCurrent: kind === IndexSearchKind.Interior,
                  to: `/?kind=interior`,
                  label: "家具",
                  replace: false,
                },
              ]}
            >
              {kind === IndexSearchKind.Project && <TopProjectListContainer />}
              {kind === IndexSearchKind.Post && <TopPostListContainer />}
              {kind === IndexSearchKind.Interior && (
                <TopProductListContainer kind={ProductKind.INTERIOR} />
              )}
              {kind === IndexSearchKind.BuildingMaterial && (
                <TopProductListContainer kind={ProductKind.BUILDING_MATERIAL} />
              )}
            </TabsLayout>
          </div>
        </div>
      </main>
    </DefaultLayout>
  );
};

const TopProjectListContainer: FC = () => {
  const { pagination, parameter, buildingTypeId } = useProjectSearch(
    "/",
    { kind: "project" },
    12,
  );
  const { data, updateItem, removeItem } = pagination;
  const entities = useBuildingTypes();
  const { conditions } =
    useTopSearchConditions<ArchitectureSearchQueryParameter>({
      entities,
      queryKey: "building_type_id",
      parameter,
    });

  return (
    <TopItemListContainer
      conditions={conditions}
      moreLink={
        buildingTypeId
          ? `/architectures/projects/search?building_type_id=${buildingTypeId}`
          : "/architectures/projects/search"
      }
      displayMoreLink={pagination.dataLength > 0}
    >
      <ProjectItemList>
        {data.map((project) => {
          return (
            <ProjectItem
              key={project.id}
              project={project}
              mutate={(updatedProject) => updateItem(updatedProject, false)}
              removeMutation={() => removeItem(project)}
            />
          );
        })}
      </ProjectItemList>
    </TopItemListContainer>
  );
};

const TopPostListContainer: FC = () => {
  const { pagination, parameter, useTypeId } = usePostSearch("/", {
    kind: "post",
  });
  const { data, updateItem } = pagination;
  const entities = useAllUseTypes();
  const { conditions } =
    useTopSearchConditions<ArchitectureSearchQueryParameter>({
      entities,
      queryKey: "use_type_id",
      parameter,
    });

  return (
    <TopItemListContainer
      conditions={conditions}
      moreLink={
        useTypeId
          ? `/architectures/posts/search?use_type_id=${useTypeId}`
          : `/architectures/posts/search`
      }
      displayMoreLink={pagination.dataLength > 0}
    >
      <PostItemList
        posts={data}
        onRender={(post) => (
          <PostItem
            key={post.id}
            post={post}
            mutate={(updated) => updateItem(updated, false)}
          />
        )}
      />
    </TopItemListContainer>
  );
};

type TopProductListContainerProps = {
  kind: ProductKind;
};

const TopProductListContainer: FC<TopProductListContainerProps> = ({
  kind,
}) => {
  const { pagination, parameter, categoryId } = useProductSearch(
    "/",
    kind,
    {
      kind: kind === ProductKind.INTERIOR ? "interior" : "building_material",
    },
    24,
  );
  const { data, updateItem } = pagination;
  const { existInteriorCategories, existMaterialCategories } =
    useExistCategories();
  const { conditions } = useTopSearchConditions<ProductSearchQueryParameter>({
    entities:
      kind === ProductKind.INTERIOR
        ? existInteriorCategories
        : existMaterialCategories,
    queryKey: "category_id",
    parameter,
  });

  const queryParam = useMemo(
    () => (categoryId ? `?category_id=${categoryId}` : ""),
    [categoryId],
  );

  return (
    <TopItemListContainer
      conditions={conditions}
      moreLink={
        kind === ProductKind.BUILDING_MATERIAL
          ? `/building_materials/product_variations/search${queryParam}`
          : `/interiors/product_variations/search${queryParam}`
      }
      displayMoreLink={pagination.dataLength > 0}
    >
      <ProductVariationItemList
        productVariations={data}
        onRender={(variation) => (
          <ProductVariationItem
            productVariation={variation}
            mutate={(updated) => updateItem(updated, false)}
          />
        )}
      />
    </TopItemListContainer>
  );
};

export default IndexPage;
