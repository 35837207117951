import client from "@/utils/api/client";
import { UseTypeEntity } from "@/web-client/api";
import useSWR from "swr";

const useAllUseTypes = (): UseTypeEntity[] => {
  const { data: useTypes } = useSWR<UseTypeEntity[]>(`/use_types`, async () => {
    const { data } = await client.useTypesGet();

    return data;
  });

  return useTypes || [];
};

export default useAllUseTypes;
