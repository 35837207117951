import { BoardResponse } from "@/web-client/api";
import Link from "next/link";
import { FC } from "react";

type Props = {
  board: BoardResponse;
};

const BoardItem: FC<Props> = ({ board }) => {
  return (
    <div className="relative bg-white rounded-sm p-16 whitespace-normal">
      <Link href={`/boards/${board.id}`} className="absolute inset-0"></Link>
      <div className="grid grid-cols-[80px_1fr] laptop:grid-cols-[minmax(80px,2fr)_minmax(0,3fr)] items-start gap-12 laptop:gap-24">
        <img
          className="block aspect-square object-cover bg-gray-100"
          src={board.upload_image.urls.small}
          alt={board.title}
        />
        <div className="space-y-8">
          <h1 className="font-bold">{board.title}</h1>
          <p className="text-xs line-clamp-3 text-secondary tracking-wider leading-loose">
            {board.description}
          </p>
        </div>
      </div>
    </div>
  );
};
export default BoardItem;
