import clsx from "clsx";
import Link from "next/link";
import { FC } from "react";
import { UrlObject } from "url";

export interface TabProps {
  isCurrent: boolean;
  to?: UrlObject | string;
  onClick?: () => void;
  as?: "link" | "button";
  icon?: string;
  label: string;
  replace?: boolean;
  linkAs?: UrlObject | string;
}

const BaseClass =
  "Tab whitespace-nowrap flex flex-row items-center py-12 no-underline space-x-4";

const Tab: FC<TabProps> = ({
  isCurrent,
  to,
  icon,
  label,
  as = "link",
  onClick,
  replace = false,
  linkAs,
}): JSX.Element => {
  if (as === "link") {
    return (
      <Link
        href={to}
        prefetch={false}
        replace={replace}
        as={linkAs}
        scroll={false}
        className={clsx(BaseClass, isCurrent && "current")}
      >
        {icon && <i className={`text-lg Icon icon-${icon}`}></i>}
        <span className="inline-block">{label}</span>
      </Link>
    );
  }

  if (as === "button") {
    return (
      <button
        type="button"
        className={clsx(BaseClass, isCurrent && "current")}
        onClick={onClick}
      >
        {icon && <i className={`text-lg Icon icon-${icon}`}></i>}
        <span className="inline-block">{label}</span>
      </button>
    );
  }
};

export default Tab;
