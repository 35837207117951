import Link from "next/link";
import { FC } from "react";

type Props = {
  href: string;
  label?: string;
};

const MoreBtnLink: FC<Props> = ({ href, label = "View More" }): JSX.Element => {
  return (
    <Link
      href={href}
      className="block bg-yellow-500 py-16 px-24 font-bold rounded-full text-center max-w-[300px] mx-auto"
    >
      {label}
    </Link>
  );
};

export default MoreBtnLink;
