import { CategoryEntity, CategoryResponse } from "@/web-client/api";
import { useMemo } from "react";
import useCategories from "./useCategories";

interface UseCategoryResponse {
  existCategories: CategoryResponse[];
  existInteriorCategories: CategoryResponse[];
  existMaterialCategories: CategoryResponse[];
}

const useExistCategories = (): UseCategoryResponse => {
  const { categories, interiorCategories, materialCategories } =
    useCategories();

  const callback = (c: CategoryEntity | CategoryResponse) =>
    c.product_variations_count > 0;

  const existInteriorCategories = useMemo(
    () =>
      interiorCategories.filter(callback).map((c: CategoryResponse) => {
        if (c.children) {
          c.children = c.children.filter(callback);
        }

        return c;
      }),
    [interiorCategories],
  );

  const existMaterialCategories = useMemo(
    () =>
      materialCategories.filter(callback).map((c: CategoryResponse) => {
        if (c.children) {
          c.children = c.children.filter(callback);
        }

        return c;
      }),
    [materialCategories],
  );

  return {
    existCategories: categories.filter(callback),
    existInteriorCategories,
    existMaterialCategories,
  };
};

export default useExistCategories;
